import { IconHelp, IconDashboard, 
        //  IconGauge, 
         IconCalculator, IconTag,IconRotate,IconTestPipe2, 
         IconTruckDelivery, 
         IconCategory,
         IconCheckupList,
         IconMoodHappy,
         IconBrandApplePodcast,
         IconDatabase,
         Icon3dCubeSphere,
         IconReceipt,
         IconSocial,
         IconTable,
         IconShield,
        } from '@tabler/icons';

const icon = {
    "dashboard" : <IconDashboard color="green"/>,
    "saleR"     : <IconCalculator/>,
    "salerfidR" : <IconTag/>,
    "sale"      : <IconCategory/>,
    "order"     : <IconCheckupList/>,
    "delivery"  : <IconTruckDelivery/>,
    "rotation"  : <IconRotate/>,
    "member"    : <IconMoodHappy/>,
    "afterService"    : <IconBrandApplePodcast/>,
    "stock"     : <IconDatabase/>,
    "sample"    : <IconTestPipe2/>,

    "dashboardPrivate" : <IconDashboard color="green"/>,
    // "dashboardPrivate" : <IconGauge color="black"/>,     
    
    "customer"  : <IconMoodHappy    color="black"/>,
    "business"  : <IconCheckupList  color="black"/>,
    "rent"      : <Icon3dCubeSphere color="black"/>,
    "sales"     : <IconCategory color="black"/>,
    "account"   : <IconReceipt  color="black"/>,
    "community" : <IconSocial   color="black"/>,
    "basics"    : <IconTable    color="black"/>,    
    "system"    : <IconShield    color="black"/>,    
    
}

const MenuIcon = (props) => {
    let iconcd = props.menuid;
    return ( <>{icon[iconcd] ? icon[iconcd] : <IconHelp />}</>);
}

export default MenuIcon;