import { useEffect, useState } from "react";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import {
  Toolbar,
  Box,
  CssBaseline,
  ToggleButton,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import { Outlet, useNavigate, useLocation } from "react-router-dom";

import Sidebar from "./sidebar";
import Header from "./header";
import HeaderQuickMenu from "./header/HeaderQuickMenu";
import { drawerWidth } from "./constant";

import { useDispatch, useSelector } from "react-redux";
import { SideOpen, SideClose } from "../../store/SideOpenSlice";
import { setProfile } from "../../store/ProfileSlice";
// import { setLogout } from "../../store/LoginSlice";
//import AxiosCommon from "../../components/axios/AxiosCommon";
import dayjs from "dayjs";
import "./AgGrid.css";
import "../common/css/Table.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { isMobile } from 'react-device-detect';
import Logo from "../../assets/logo.png";
// import LogoMobile from "../../assets/logoRentMobile.png";
//import { MenuInfo } from "./sidebar/MenuInfo";
import MenuListMobile from "./sidebar/MenuListMobile";
import HeaderNotice from "../common/header/HeaderNotice"
import HeaderShop from "../common/header/HeaderShop"

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

// eslint-disable-next-line
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const PosMain = (props) => {
  const [open, setOpen] = useState(isMobile ? false : true);
  const dispatch = useDispatch();
  const location = useLocation();
  const isLogged = useSelector((state) => state.Login?.value);
  const lastLoginDt = useSelector((state) => state.Profile?.data?.lastlogindt);
  const profile = useSelector((state) => state.Profile?.data);
  const menuInfo = useSelector((state) => state.MenuInfo?.data);
  const menuGroup = () => {
    let menuList = {};
    menuInfo?.map((item) => {
      return (menuList[item.menuid] = false);
    });
    return menuList;
  };

  const navigate = useNavigate();
  const handleDrawerOpen = () => {
    setOpen(open ? false : true);
    open ? dispatch(SideOpen()) : dispatch(SideClose());
  };
  const handleDrawerOpenMenuList = () => {
    setOpen(false);
    open ? dispatch(SideOpen()) : dispatch(SideClose());
  };
  const [tapsValue, setTapsValue] = useState(false);
  const [collapseOpen, setcollapseOpen] = useState(menuGroup);
  //const axiosCommon = AxiosCommon();
  //로그아웃
  useEffect(() => {
    if (!isLogged) {
      //로그인일자가 없을경우(다른텝에서 로그아웃)
      if (!!lastLoginDt) {
        try {
          //axiosCommon.delete("/pos/authentication/logout");
        } catch (error) {
          window.alert(error);
        } finally {
        }
      }
      let companyCd = profile?.company_cd;
      let userId = profile?.user_cd;
      let shopCd = profile?.shop_cd;
      localStorage.clear();
      dispatch(setProfile({company_cd:companyCd,user_cd:userId,shop_cd:shopCd}));
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isLogged]);

  //자동로그아웃 1시간간격체크
  setInterval(() => {
    let today = dayjs().format("YYYYMMDD");

    if (!lastLoginDt) return;
    console.log(lastLoginDt, today);
    if (lastLoginDt !== today) {
      // dispatch(setLogout());
    }
  }, 3600000);

  const handleClickTabs = (e, value) => {
    setTapsValue(value);
    let menuGroupCollapse = {};
    for (const [key] of Object.entries(collapseOpen)) {
      if (key === e.target.id) {
        menuGroupCollapse[key] = true;
      } else {
        menuGroupCollapse[key] = false;
      }
    }
    setcollapseOpen(menuGroupCollapse);
    if (!open) setOpen(true);
  };
  const handleMenuGroupOpen = (key) => {
    setcollapseOpen({ ...collapseOpen, [key]: !collapseOpen[key] });
  };
  const home = () => {
    if (location.pathname === "/RentMain/business/BusniessDashboradMobile") return;
    setOpen(false);
    // navigate("/PosMain");
    navigate("/RentMain");
  }
  const topTabCreate = () => {
    if (!isMobile) {
      return <Tabs
        value={tapsValue}
        onChange={handleClickTabs}
        variant="scrollable"
        scrollButtons="auto"
      >
        {menuInfo?.map((menugroup) => {
          return (
            <Tab
              key={menugroup.menuid}
              id={menugroup.menuid}
              label={menugroup.menunm}
              style={{
                // paddingTop: "6px",
                // paddingLeft: "10px"
                color:"black",
                fontWeight: "bold",  
                fontSize:"15px"
              }} 
            />
          );
        })}
      </Tabs>
    } else {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}> 
          <img 
            src={Logo} alt="Berry" 
            // width="100"  
            height="42" 
            onClick={home} 
            style={{
              paddingTop: "6px",
              // paddingLeft: "10px"
              // color:"black"
            }} 
          />
        </div>
      );
    }
  }

  return (
    <Box
      className="PosMain.css"
      sx={{
        display: "flex",
        minHeight: "100vh",
        backgroundColor: "#F9F9F9",
      }}
    >
      <CssBaseline />
      <AppBar
        id="appbar"
        position="fixed"
        open={open}
        color="inherit"
        enableColorOnDark
      >
        <Box>
          <Toolbar>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {isMobile? 
              <MenuListMobile/>              
              :
              <ToggleButton
                color="primary"
                value="check"
                selected={open}
                onClick={handleDrawerOpen}
                sx={{ mr: 2, border: 0 }}
              >
                {open ? <MenuOpenIcon /> : <MenuIcon />}
              </ToggleButton>
              }
                            
            </Box>
            {isMobile ?
              <Box
                sx={{
                  display: "flex",  
                  flexDirection: "row-reverse",   
                  justifyContent:"center",           
                  paddingLeft:'50px'   
                }}
                style={{ flex: 1 }}
              >
                {topTabCreate()}
              </Box>
            :
              <Box
                sx={{
                  display: "flex",  
                  flexDirection: "row-reverse",   
                  justifyContent:"start",  
                }}
                style={{ flex: 1 }}
              >
                {topTabCreate()}
              </Box>
            } 
                        
            
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >           
              {isMobile? null :<HeaderQuickMenu/> }
              {isMobile? null :<span style={{ margin: '0 3px' }}>|</span> }
              {/* <span style={{ margin: '0 3px' }}></span> */}
              {isMobile? null :<HeaderNotice gridtheme={"ag-theme-alpine"} /> }
              <span style={{ margin: '0 5px' }}></span>
              {isMobile? null :<HeaderShop />}              
              <Header />
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
      <Sidebar
        drawerOpen={open}
        menuGroupOpen={collapseOpen}
        handleMenuGroupOpen={handleMenuGroupOpen}
        handleDrawerOpenMenuList={handleDrawerOpenMenuList}
      />
      {isMobile?
      <Main open={open}
        sx={{ mt: 8, pb: 0, pl: "10px", pr: "10px", pt: "0px" }}>
        {<Outlet />}
      </Main>
      :
      <Main open={open}
        sx={{ mt: 8, pb: 0, pl: "10px", pr: "10px", pt: "15px" }}>
        {<Outlet />}
      </Main>
      }
    </Box>
  );
};

export default PosMain;
