import { 
  Typography,  
  Box ,
  ToggleButton,
} from "@mui/material";
import "../common/css/dashboard.css";

// import { useNavigate } from "react-router-dom";

const HeaderQuickMenu = (props) => {  

  // const navigate = useNavigate();
  
  return (

    <Box 
    >
      <ToggleButton
        color="primary"
        value="check"
        // onClick={() => window.open('http://367.co.kr', '_blank')}
        // onClick={() => window.open('http://localhost:3003/ProgrameIntro', '_blank')}
        //onClick={() => window.open(("http://localhost:3001/rentmain/programe/MainNavBar"), '_blank')}
        onClick={() => window.open(("http://localhost:3001/member"), '_blank')}
        sx={{ border:0 ,  m:0.2, p:0}}
      >
      {/* <IconDeviceDesktop style={{ color: "#7478e8" }} /> */}
      <Typography 
        variant="h8"
        // sx={{
        //   // cursor: 'pointer', // 마우스 오버 시 손가락 모양으로 변경
        //   maxWidth: isMobile?'100px':'300px',
        //   overflow: 'hidden', // 넘치는 텍스트를 감춤
        //   textOverflow: 'ellipsis', // 넘치는 텍스트를 '...'으로 표시 "..."
        //   whiteSpace: 'nowrap', // 줄 바꿈을 방지
        //   color: 'blue', // 텍스트 색상을 파랑으로 변경          
        // }}        
        sx={{           
          color: "#fff", 
          bgcolor: "#1d4b75",          
          //bgcolor: "#0000a7", 
          fontSize: '15px',
          width:'220px',
          height:'38px',    
          borderRadius:8,
          display: 'flex',           // flex 사용
          alignItems: 'center',       // 세로 중앙 정렬
          justifyContent: 'center',   // 가로 중앙 정렬
          textAlign: 'center',        // 텍스트 중앙 정렬 (백업)
          '&:hover': {
            // backgroundColor: '#5454c3',  // 마우스 오버 시 보라
            backgroundColor: '#2180aa',  // 마우스 오버 시 군청       
            // backgroundColor: '#2221aa',  // 마우스 오버 시 군청      
          },
        }}
      >         
        프로그램 소개
      </Typography>
    </ToggleButton>
  </Box>
  );
};

export default HeaderQuickMenu;
