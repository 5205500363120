import { lazy } from "react";
import { useLocation } from "react-router-dom";
// project imports
import LoginPage from "../login/Login";
import LoginPrograme from "../../pages/programe/MainNavBar";
import MainLayout from "../common/RentMain";
import Notfoundpage from "../common/NotFoundPage";
import Loadable from "./Loadable";

// AG grid css
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

// lazy(코드분리) : export default만 가능함
// dashboard page routing
const Dashboard = Loadable(lazy(() => import("../business/BusniessDashborad")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = () => {
  const location = useLocation();
  const locationPath =
    location.pathname.split("/")[2] + "/" + location.pathname.split("/")[3];
  const Page = () => {
    const Component = Loadable(
      lazy(() =>
        import(`../` + locationPath).catch((err) => {
          return import("../common/NotFoundPage");
        })
      )
    );
    return <Component gridtheme={"ag-theme-alpine"} />;
  };
  return [
    {
      path: "/",
      element: <LoginPage />,
    },
    {
      path: "/rentmain",
      element: <MainLayout />,
      children: [
        {
          path: "/rentmain",
          element: <Dashboard gridtheme={"ag-theme-alpine"}/>,
        },
        {
          path: locationPath,
          element: <Page />,
        },
        {
          path: "*",
          element: <Notfoundpage />,
        },
      ],
    },
    {
      path: "/member",
      element: <LoginPrograme />,
    },
    {
      path: "*",
      element: <Notfoundpage />,
    },
  ];
};

export default MainRoutes;
